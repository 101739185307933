import React from 'react';
import { Routing } from '@singularsystems/neo-react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { AppService, Types } from '../Services/AppService';
import { IAppRoute } from '../../Identity/IdentityRoutes';
import { Types as IdentityTypes } from '../../Identity/IdentityTypes';
import TDPUser from '../../Identity/Models/Security/TDPUser';
import TermsAndConditionsComponent from '../../Identity/Components/TermsAndConditionsAcceptComponent';

class RouteView extends Routing.RouteView {

    private routeService = AppService.get(Types.App.Services.RouteService);
    private authService = AppService.get(IdentityTypes.Identity.Services.TDPAuthenticationService)

    constructor(props: any) {
        super(props);
        this.routes = this.routeService.routes;
        this.switchComponent = Switch;
        this.routeComponent = Route;

        this.getForbiddenComponent = (route) => <h2>Forbidden</h2>;
        this.getSigningInComponent = (route) => <div>Signing in...</div>;
    }

    protected getAuthorisedComponent(route: IAppRoute, path: string) {
        if (!route.allowAnonymous) {
            var user = this.authService.user as TDPUser;            
            if (user != null) {
                if ((!user.hasAccepted)) {
                    return <TermsAndConditionsComponent />
                }
            }
        }

        return super.getAuthorisedComponent(route, path);
    }
}

export default withRouter(RouteView);