import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { NotifyUtils } from '@singularsystems/neo-core';
import { Neo, Views } from '@singularsystems/neo-react';
import { Observer, observer } from 'mobx-react';
import './Styles/App.scss';
import Sidebar from './Components/Sidebar';
import HeaderPanel from './Components/HeaderPanel';
import RouteView from './Components/RouteView';
import AppLayout, { MenuState } from './Services/AppLayout';
import NeoLogo from './assets/img/Neo-charcoal.png';
import "@singularsystems/neo-reporting/styles/reporting.scss";
import { AppService } from '../Identity/CommonIdentityTypes';
import Types from './AppTypes';
// import "@singularsystems/neo-canvas-grid/styles/";

@observer
export default class App extends React.Component {

    public config = AppService.get(Types.App.Config);
    private styleVariable: string = "";

    public render() {

        var env = this.config.SiteMode;

        if (env !== "") {
            if (env.toUpperCase().trim() !== 'DEV' && env.toUpperCase().trim() !== 'PRD') {
                if (env.toUpperCase().trim() === 'UAT') {
                    this.styleVariable = "uat-mode";
                } else if (env.toUpperCase().trim() === 'QA') {
                    this.styleVariable = "qa-mode";
                }
            }
        }

        const layout = AppLayout.current;

        return (
            <BrowserRouter>
                <main>
                    <div className={"app-container " + (layout.menuState === MenuState.Collapsed ? "mini-nav" : "")}>

                        {/* Left menu panel */}
                        <Sidebar />

                        {/* Top header panel */}
                        <HeaderPanel />
                        <div className={"responsiveOverlay " + (layout.menuVisible ? "show" : "")} onClick={(e) => layout.showMenu(e)}></div>

                        {/* Right content panel */}
                        <div className={"app-right-panel " + this.styleVariable}>

                            <Observer>
                                {() => (
                                    <Neo.Loader task={Views.ViewBase.currentView ? Views.ViewBase.currentView.taskRunner : undefined} className="page-loader" showSpinner={false} />
                                )}
                            </Observer>

                            <div className={"app-content-area container-fluid" + (layout.pinHeader ? " pinned" : "")}>
                                <React.Suspense fallback={<div>Loading...</div>}>
                                    <RouteView />
                                </React.Suspense>
                            </div>                            

                        </div>

                        <Neo.ModalContainer />
                        <Neo.ToastContainer notificationStore={NotifyUtils.store} />
                        <Neo.TooltipProvider />
                        <Neo.ContextMenuContainer />

                    </div>
                </main>
            </BrowserRouter>
        );
    }
}